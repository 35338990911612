import React from 'react';
import './App.css';
import Header from './Components/Header.js'
import { Icon } from './Components/IconAnimate.js'
import { AnimateWord } from './Components/AnimateWords.js'
import phone from './phone.png';
import basics1 from './basics_full.svg';
// import icons1 from './App icon SNKRS.png';
// import icons2 from './App icon Here.png';
// import icons3 from './App icon Playboy.png';
// import icons4 from './App icon Tasty.png';
// import icons5 from './App icon Watchdog.png';

function App() {
  return (
    <div className="App">

      <Header />

      <div className="Background"></div>

    

      <header className="App-header">
        <AnimateWord />
        
        {/* <h1>
          Nick Bolton
        </h1> */}
        <p>
          iOS and Mac Engineer
          <br></br><br></br>
          I like pixels.
        </p>
        
      </header>

      <div className="Basics">
      
        <Icon />

      {/* <div className="Icons">
          <div className="icon1"><img src={icons1} alt="Nike" max-width="100%" height="auto"></img></div>
          <div className="icon2"><img src={icons2} alt="Here"></img></div>
          <div className="icon3"><img src={icons3} alt="Playboy"></img></div>
          <div className="icon4"><img src={icons4} alt="Tasty"></img></div>
          <div className="icon5"><img src={icons5} alt="Watchdog"></img></div>
          <div className="icon6"></div>
          <div className="icon7"></div>
        </div> */}
      
      <h1>
          The basics
        </h1>
        <p>
        I’m an iOS engineer with an innate drive towards building software that tackles some of the biggest challenges yet to be solved.
        </p>
        <img className="Years" src={basics1} alt="hello"></img>
      </div>

      <div className="Apps">
      <h1>
          Published apps
        </h1>
        <p>
        I don’t just play, I publish! Here are a few examples of the work I’ve published alongside small and large agile teams and independently.
        </p>
      </div>

      <div className="Apps-list">
        <div className="left-text" data-color="red">
          <h2>Nike</h2>
          <p>
          <a href="https://apps.apple.com/us/app/snkrs/id911130812" target="_blank">SNKRS app  →</a>
          <br></br><br></br>
          Cop or drop? That is the question. I helped the Nike agile team implement waitline and pre-reciept features essential to the SNKRS experience. You can’t always get what you want, but you can at least get in line for it!
          </p>
        </div>
        <div className="left-app">
          {/* <img src={phone} alt="hello" height="648px" width="365px"></img> */}
          <video width="239" height="512" loop="true" autoplay="autoplay" muted="true" playsinline="true">
            <source src={require ('./images/snkrs.mp4')} type="video/mp4" />
          </video>
        </div>

        <div className="right-app">
          {/* <img src={phone} alt="hello" height="648px" width="365px"></img> */}
          <video width="239" height="512" loop="true" autoplay="autoplay" muted="true" playsinline="true">
            <source src={require ('./images/here.mp4')} type="video/mp4" />
          </video>
        </div>
        <div className="right-text">
          <h2>Here</h2>
          <p>
          <a href="https://appadvice.com/app/here-talk-text-watch/1541854332" target="_blank">Here app  →</a>
          <br></br><br></br>
          Wanna get weird with chat and video conferencing? Go there, er I mean Here. I built the first version of the Here app and led the team to build out group chat, room talk and video, user card features. 
          </p>
        </div>

        <div className="left-text">
          <h2>Playboy</h2>
          <p>
          <a href="https://appadvice.com/app/the-playboy-coloring-book/1451866928" target="_blank">Coloring Book app  →</a>
          <br></br><br></br>
          My centerfold shots leave something to be desired, but my ability to implement a deceptively simple coloring book experience as the sole developer…still did not get me an invite to the mansion. But, let’s just say you’ll be thoroughly satisfied.
          </p>
        </div>
        <div className="left-app" data-color="blue">
        {/* <img src={phone} alt="hello" height="648px" width="365px"></img> */}
        <video width="239" height="512" loop="true" autoplay="autoplay" muted="true" playsinline="true">
            <source src={require ('./images/playboy.mp4')} type="video/mp4" />
          </video>
        </div>

        <div className="right-app">
        {/* <img src={phone} alt="hello" height="648px" width="365px"></img> */}
        <video width="239" height="512" loop="true" autoplay="autoplay" muted="true" playsinline="true">
            <source src={require ('./images/tasty.mp4')} type="video/mp4" />
          </video>
        </div>
        <div className="right-text">
          <h2>Buzzfeed</h2>
          <p>
          <a href="https://apps.apple.com/us/app/tasty/id1217456898" target="_blank">Tasty app  →</a>
          <br></br><br></br>
          Tasty makes it easier than ever to bake a giant batch of cookies at 1am for no reason. Could they make it even easier? I helped Tasty integrate with Walmart GroceryPickup to answer that call and it’s pretty freaking easy now.
          </p>
        </div>

        <div className="left-text">
          <h2>Retro. Ad-Free Social</h2>
          <p>
          <a href="https://apps.apple.com/us/app/retro-ad-free-social/id6443709020" target="_blank">Retro app  →</a>
          <br></br><br></br>
          Your friends, week to week. Retro is a friends-only photo journal where you share for yourself as much as your friends. I built the video editor, collage builder and recap generator.
          </p>
        </div>
        <div className="left-app">
        {/* <img src={phone} alt="hello" height="648px" width="365px"></img> */}
        <video width="239" height="512" loop="true" autoplay="autoplay" muted="true" playsinline="true">
            <source src={require ('./images/retro.mp4')} type="video/mp4" />
          </video>
        </div>

        <div className="right-app">
        {/* <img src={phone} alt="hello" height="648px" width="365px"></img> */}
        <video width="239" height="512" loop="true" autoplay="autoplay" muted="true" playsinline="true">
            <source src={require ('./images/peepee.mp4')} type="video/mp4" />
          </video>
        </div>
        <div className="right-text">
          <h2>Self published</h2>
          <p>
          <a href="https://apps.apple.com/us/app/watchdog-automatic-dog-timer/id1367124623" target="_blank">WatchDog app  →</a>
          <br></br><br></br>
          When you doggo go, you doggo go. Help a pupper out and keep track of how long YOU made him wait. I partnered with a dog-concerned designer to create this simple and elegant solution so your dog’s not ‘doin me a accident’ on your shag carpet. All animations are performed natively with Core Animation.
          </p>
        </div>



      </div>


      <div className="Deets">
        <h1>
          Deets
        </h1>
        <div className="Deets-items">
          <div className="Education">
          <h3>Education</h3>
          <hr></hr>
            <div className="EduList">
              <p>Arizona State University, Tempe, Arizona — 
                <br></br>
                B.S. Computational Mathematics
                </p>
              </div>
            <h3>Skills</h3>
            <hr></hr>
            <ul>
              <li>iOS & Mac technologies</li>
              <br></br>
              <li>Swift/SwiftUI</li>
              <br></br>
              <li>UIKit</li>
              <br></br>
              <li>Core Animation</li>
              <br></br>
              <li>Objective-C</li>
              <br></br>
              <li>Placing pixels</li>
              <br></br>
              <li>Working with designers</li>
              <br></br>
            </ul>
            <hr className="RuleSkills"></hr>
          </div>
          <div className="Work-history">
            <h3>Work history</h3>
            <hr></hr>
            <div className="grid">
              <div className="item1">H3r3, Inc.</div>
              <div className="item2">→</div>
              <div className="item3">1/2021 – 7/2024</div>
            </div>
            <p>Lead iOS Engineer</p>
            <hr></hr>
            <div className="grid">
              <div className="item1">Lone Palm Labs, Inc.</div>
              <div className="item2">→</div>
              <div className="item3">2/2023 – 10/2023</div>
            </div>
            <p>iOS Contractor</p>
            <hr></hr>
            <div className="grid">
              <div className="item1">Buzzfeed</div>
              <div className="item2">→</div>
              <div className="item3">8/2019 – 4/2020</div>
            </div>
            <p>iOS Contractor</p>
            <hr></hr>
            <div className="grid">
              <div className="item1">Playboy</div>
              <div className="item2">→</div>
              <div className="item3">1/2019 – 8/2019</div>
            </div>
            <p>iOS Contractor</p> 
            <hr></hr>
            <div className="grid">
              <div className="item1">Stack</div>
              <div className="item2">→</div>
              <div className="item3">4/2018 – 1/2019</div>
            </div>
            <p>Software Engineer
              <br></br>
              Consultant</p>
            <hr></hr>
            <div className="grid">
              <div className="item1">Insitome</div>
              <div className="item2">→</div>
              <div className="item3">5/2016 – 4/2018</div>
            </div>
            <p>Senior iOS Engineer</p>
            <hr></hr>
            <div className="grid">
              <div className="item1">Nike</div>
              <div className="item2">→</div>
              <div className="item3">5/2015 – 5/2016</div>
            </div>
            <p>Senior iOS Engineer</p>
            <hr></hr>
            <div className="grid">
              <div className="item1">Mutual Mobile</div>
              <div className="item2">→</div>
              <div className="item3">5/2012 – 2/2014</div>
            </div>
            <p>Principal iOS Engineer</p>
            <hr></hr>
          </div>

        </div>
      </div>

      <div className="Contact">
        <div className="Contact-grid">
        <h1 className="Title">
          Contact &<br></br>resumé
        </h1>
          <div className="Card">
            <h1>Nick Bolton</h1>
            <div className="Info">
              <a href="tel:+1 5128276762">512-827-6762</a>
              <br></br>
              <a href="mailto:nick@tubebreakup.com?Subject=Hellooooo" target="_top">nick@tubebreakup.com</a>
            </div>
            <div className="Download"><a href={require ('./images/resume.pdf')} download="nick-bolton-resume.pdf"><img src={require ('./images/downloadBig.svg')} width="40" height="40" /></a></div>
          </div>
      
        </div>
    
      </div>


    </div>
    


    
  );
}

export default App;
