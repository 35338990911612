import React from 'react'
import './IconAnimate.css'
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import {useRef, useState, useEffect} from 'react';


// const iconVariants = {
//     off: { opacity:0 },
//     on: { opacity:1, y: -100 }
// }



export function Icon() {
    // const [isOn, setOn] = useState(false);
    // // const { scrollY } = useViewportScroll()
    // // const containerRef = useRef(null);


    // useEffect(() => {
    //     // scrollY.onChange()
    //     const scrollTop = window.pageYOffset

    //     if (scrollTop > 200) {
    //         setOn(true)
    //         } else {
    //           setOn(false)
    //           }

    // }, []);
   
   
    
   
    return (
    
        // <motion.div className="Icons" initial={false} animate={isOn ? "on" : "off"}>
        <div className="center">
        <div className="Icons">
          <motion.div className="icon1"
          animate={{
            rotate: [-8, -10, -7, -10, -8],
            y: [5, -5, 5, -5, 5],
            x: [20, 17, 20, 17, 20]
            }}
            transition={{
                duration: 4,
                ease: "easeInOut",
                times: [0, 0.2, 0.5, 0.8, 1],
                loop: Infinity,
                repeatDelay: 0
            }}
          ><img src={require ('../images/App icon SNKRS.png')} alt="Nike"></img></motion.div>
          <motion.div className="icon2"
          animate={{
            rotate: [6, 3, 5, 3, 6],
            y: [145, 140, 145, 140, 145],
            x: [-20, -17, -20, -17, -20]
            }}
            transition={{
                duration: 4.5,
                ease: "easeInOut",
                times: [0, 0.2, 0.5, 0.8, 1],
                loop: Infinity,
                repeatDelay: 0
            }}
          ><img src={require ('../images/App icon Here.png')} alt="Here"></img></motion.div>
          <motion.div className="icon3"
          animate={{
            rotate: [-5, -10, -7, -10, -5],
            y: [5, -5, 5, -5, 5],
            x: [20, 17, 20, 17, 20]
            }}
            transition={{
                duration: 7,
                ease: "easeInOut",
                times: [0, 0.2, 0.5, 0.8, 1],
                loop: Infinity,
                repeatDelay: 0
            }}
          ><img src={require ('../images/App icon Playboy.png')} alt="Playboy"></img></motion.div>
          <motion.div className="icon4"
          animate={{
            rotate: [20, 10, 17, 10, 20],
            y: [105, 100, 105, 100, 105],
            x: [-20, -17, -20, -17, -20]
            }}
            transition={{
                duration: 5.8,
                ease: "easeInOut",
                times: [0, 0.2, 0.5, 0.8, 1],
                loop: Infinity,
                repeatDelay: 0
            }}
          ><img src={require ('../images/App icon Tasty.png')} alt="Tasty"></img></motion.div>
          <motion.div className="icon5"
          animate={{
            rotate: [-20, -5, -10, -5, -20],
            y: [5, -5, 5, -5, 5],
            x: [20, 17, 20, 17, 20]
            }}
            transition={{
                duration: 5,
                ease: "easeInOut",
                times: [0, 0.2, 0.5, 0.8, 1],
                loop: Infinity,
                repeatDelay: 0
            }}
          ><img src={require ('../images/App icon Retro.png')} alt="Watchdog"></img></motion.div>
          <motion.div className="icon6"
          animate={{
            rotate: [30, 5, 10, 5, 30],
            y: [85, 80, 85, 80, 85],
            x: [-20, -17, -20, -17, -20]
            }}
            transition={{
                duration: 6.1,
                ease: "easeInOut",
                times: [0, 0.2, 0.5, 0.8, 1],
                loop: Infinity,
                repeatDelay: 0
            }}
          ><img src={require ('../images/App icon Watchdog.png')} alt="Watchdog"></img></motion.div>
          <motion.div className="icon7"
          animate={{
            rotate: [-30, -5, -10, -5, -30],
            y: [5, -5, 5, -5, 5],
            x: [20, 17, 20, 17, 20]
            }}
            transition={{
                duration: 6.6,
                ease: "easeInOut",
                times: [0, 0.2, 0.5, 0.8, 1],
                loop: Infinity,
                repeatDelay: 0
            }}
          ></motion.div>
        </div>
        </div>
   ) 

  }
