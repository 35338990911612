import React from 'react'
import './Header.css'
import { motion } from 'framer-motion';


class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hasScrolled: false
    }
  }
  
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  handleScroll = event => {
    const scrollTop = window.pageYOffset
    
    if (scrollTop > 1200) {
      this.setState({hasScrolled: true})
      } else {
        this.setState({hasScrolled: false})
        }
    }


  render() {
    return (
      <div className={this.state.hasScrolled ? 'Header HeaderScrolled' : 'Header'}>
        <motion.div className="HeaderGroup" whileHover={{ scale: 1.015 }} whileTap={{ scale: 0.97 }}>
            
            <a className="Name" href="https://notlob.io">Nick Bolton</a>
            <div className="columnDivider1"></div>
            <a classname="Header-button" href="tel:+1 5128276762"><img src={require ('../images/phone.svg')} width="44" height="44" /></a>
            <div className="columnDivider"></div>
            <a classname="Header-button" href="mailto:nick@pixeol.com?Subject=Hellooooo" target="_top"><img src={require ('../images/mail.svg')} width="44" height="44" /></a>
            <div className="columnDivider"></div>
            <a href={require ('../images/resume.pdf')} download="nick-bolton-resume.pdf"><img src={require ('../images/download.svg')} width="44" height="44" /></a>
        </motion.div>
      </div>
   ) 

  }
}


export default Header

