import React from 'react'
import { motion } from 'framer-motion';



export function AnimateWord() {

    const string_to_array = function (str) {
        return str.trim().split(" ");
    };

    const string = string_to_array("Nick\u00A0 Bolton")
   
    const containerVariants = {
        before: {},
        after: { transition: { staggerChildren: 1 } },
      }
        
    // Variants for animating each letter
    const wordVariants = {
    before: {
        opacity: 0,
        y: 20,
        transition: {
            type: "spring",
            damping: 16,
            stiffness: 200,
        },
    },
    after: {
        opacity: 1,
        y: 0,
        transition: {
            type: "spring",
            damping: 16,
            stiffness: 200,

            },
        },
    }



    return (
       
        <motion.div
            center={ "y" }
            height={ 26 }
            width={ "100%" }
            background={ "" }
            style={{
                fontFamily: "Inter, Work Sans, sans-serif",
                fontSize: "74px",
                letterSpacing: "-5.4px",
                lineHeight: "1.2",
                fontWeight: "800",
                color: "#black",
                display: "flex", // Set the display value to flex
                justifyContent: "center", // Center all children elements on the x axis
            }}
            variants={ containerVariants }
            initial={ "before" }
            animate={ "after" }
        >
            {string.map((word, index) => (
                <motion.div
                    key={ index }
                    width={ "auto" } // Set the width to the width of the word
                    height={ 26 } // Set the height to the height of the text
                    background={ "" }
                    style={{ position: "relative" }} // Position elements
                    variants={ wordVariants }
                >
                    {/* // Set any spaces to a non-breaking space */}
                    {word === " " ? "\u00A0" : word}
                </motion.div>
                ))
            }
        </motion.div>
    )
  }
